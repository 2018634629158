@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';












































































































.evaluate-recognition-sidebar__comment {
  .v-textarea {
    height: 100%;
  }

  .v-input__control {
    height: 100%;
  }

  .v-input__slot {
    flex-grow: 1;
  }

  .v-text-field__details {
    flex-grow: 0;
  }
}
